import React from 'react';
import '../../App.css';
import Banner from '../Banner';

function Home() {
	return (
		<>
			<div className='home'>
				<Banner />
			</div>
		</>
	);
}

export default Home;
